/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';

import {
  Typography,
  IconButton,
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Link,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import emailAddresses from 'email-addresses';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { MuiTelInput } from 'mui-tel-input';
import { useNavigate } from 'react-router-dom';
import iso3166 from 'iso-3166-1';
import { addValue, getValue, removeValue } from '../../utils/storage';
import urls from '../../shared/urls';
import requestWithAuth from '../../utils/requestWithAuth';
import { get as registryGet } from '../../lib/appRegistry';
import Footer from './Footer';

const regStateDefaultValues = {
  email: '',
  password: '',
  phoneNumber: '',
  fullname: '',
  companyName: '',
  itn: '',
  position: '',
  adPhoneNumber: '',
};
const useStyles = makeStyles((theme) => ({
  mainBoxWrapper: {
    marginTop: '20vh',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    height: '95vh',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
  },
  mainArea: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
    },
  },
  fieldsBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      minWidth: '90%',
    },
  },
  fieldsArea: {
    minWidth: '300px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: '90%',
    },
  },
  linksWrapper: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  buttonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  buttons: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  passwordField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  inputFields: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  footerWrapper: {
    marginTop: theme.spacing(4),
    width: 700,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    marginBottom: theme.spacing(4),
  },
  checkboxLink: {
    display: 'inline',
  },
  checkboxWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '90%',
    },
  },
  checkboxGroup: {
    display: 'flex',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '90%',
      gap: theme.spacing(1),
    },
  },
  topLogo: {
    width: '80px',
    height: '80px',
    marginTop: theme.spacing(1),
  },
}));

const DEFAULT_PHONE_REGION = registryGet('config').registration.defaultPhoneRegion;

function Signup() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isPersonalDataChecked, setIsPersonalDataChecked] = useState(false);
  const [isAddonsTermsChecked, setIsAddonsTermsCheked] = useState(false);

  const [regState, setRegState] = useState(regStateDefaultValues);
  const {
    email,
    password,
    phoneNumber,
    fullname,
    companyName,
    itn,
    position,
    adPhoneNumber,
  } = regState;
  const [showPassword, setShowPassword] = useState(false);
  const [highlightError, setHighlightError] = useState(false);

  const {
    projectUrl, logoUrl,
  } = registryGet('config');

  useEffect(() => {
    const savedRegState = getValue('regState');
    if (savedRegState) {
      removeValue('regState');
      setRegState(JSON.parse(savedRegState));
    }
  }, []);

  const handleChangeRegisterField = (field) => (ev) => {
    setRegState((state) => ({ ...state, [field]: ev.target.value }));
  };
  const handleChangePhoneNubmer = (phone) => {
    setRegState((state) => ({ ...state, phoneNumber: phone }));
  };
  const handleChangeAdPhoneNubmer = (phone) => {
    setRegState((state) => ({ ...state, adPhoneNumber: phone }));
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const onLinkClick = (path) => {
    addValue('regState', JSON.stringify(regState));
    // eslint-disable-next-line
    window.open(path, '_blank');
  };

  function getValidCountryCode(code) {
    const isValidCode = iso3166.whereAlpha2(code.toUpperCase()) !== undefined;
    if (isValidCode) {
      return code;
    }
    return null;
  }

  const isDataValid = (data) => {
    const isEmailValid = !_.isEmpty(data.email)
      && !!emailAddresses.parseOneAddress(data.email);

    if (!data.fullname || !data.password || !data.phoneNumber || !isEmailValid) {
      setHighlightError(true);
      return false;
    }
    return true;
  };

  const onRegisterHandler = useCallback((e) => {
    e.preventDefault();

    if (!isDataValid(regState)) {
      return;
    }
    requestWithAuth.post(urls.signup, regState).then(() => {
      navigate('/verify-email', {
        state: {
          email: regState.email,
        },
      });
    })
      .catch((err) => {
        let message = '';

        if (!err.request) {
          message = 'Что-то пошло не так, но мы уже получили об этом информацию и работаем над этим';
          captureException(err);
        } else if (!err.response) {
          message = 'Возникла проблема с обработкой вашего запроса. Проверьте подключение к интернету.';
        } else if (err.response.data?.message) {
          message = err.response.data?.message;
        } else {
          message = 'Произошла ошибка при регистрации';

          if (_.startsWith(err.response.status, 5)) {
            captureException(err);
          }
        }

        enqueueSnackbar(message, {
          variant: 'error',
          autoHideDuration: 5000,
          action: (
            <IconButton
              onClick={() => {
                closeSnackbar();
              }}
            >
              <CloseIcon />
            </IconButton>
          ),
        });
      });
  }, [regState]);

  const isEmailValid = !_.isEmpty(email) && !!emailAddresses.parseOneAddress(email);

  return (
    <div>
      <Box className={classes.mainBoxWrapper}>
        <Box className={classes.logoLink}>
          <Link href={projectUrl}>
            <img src={logoUrl} alt="logo" className={classes.topLogo} />
          </Link>
        </Box>
        <Typography variant="h4" gutterBottom>
          Регистрация
        </Typography>
        <form onSubmit={onRegisterHandler} method="post">
          <div className={classes.mainArea}>
            <div className={classes.fieldsBox}>
              <div className={classes.fieldsArea}>
                <TextField
                  variant="outlined"
                  label="Ф.И.О"
                  onChange={handleChangeRegisterField('fullname')}
                  value={fullname}
                  margin="normal"
                  className={classes.inputFields}
                  required
                  error={!fullname && highlightError}
                />

                <TextField
                  id="username"
                  name="username"
                  autoComplete="new-username"
                  variant="outlined"
                  label="Email"
                  onChange={handleChangeRegisterField('email')}
                  value={email}
                  margin="normal"
                  className={classes.inputFields}
                  required
                  error={!isEmailValid && highlightError}
                />
                <div className={classes.passwordField}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="password" error={!password && highlightError} required>Пароль</InputLabel>
                    <OutlinedInput
                      id="password"
                      name="password"
                      autoComplete="new-password"
                      placeholder=""
                      label="Пароль"
                      variant="outlined"
                      value={password}
                      onChange={handleChangeRegisterField('password')}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )}
                      required
                      error={!password && highlightError}
                    />
                  </FormControl>
                </div>
                <div className={classes.inputFields}>
                  <MuiTelInput
                    value={phoneNumber}
                    label="Мобильный телефон"
                    onChange={handleChangePhoneNubmer}
                    defaultCountry={getValidCountryCode(DEFAULT_PHONE_REGION)}
                    style={{
                      width: '100%',
                    }}
                    required
                    error={!phoneNumber && highlightError}
                  />
                </div>
              </div>
              <div className={classes.fieldsArea}>
                <TextField
                  variant="outlined"
                  label="Наименование компании"
                  onChange={handleChangeRegisterField('companyName')}
                  value={companyName}
                  margin="normal"
                  className={classes.inputFields}
                />
                <TextField
                  type="number"
                  variant="outlined"
                  label="ИНН"
                  onChange={handleChangeRegisterField('itn')}
                  value={itn}
                  margin="normal"
                  className={classes.inputFields}
                />
                <TextField
                  variant="outlined"
                  label="Должность"
                  onChange={handleChangeRegisterField('position')}
                  value={position}
                  margin="normal"
                  className={classes.inputFields}
                />
                <div className={classes.inputFields}>
                  <MuiTelInput
                    value={adPhoneNumber}
                    onChange={handleChangeAdPhoneNubmer}
                    label="Рабочий телефон"
                    defaultCountry={getValidCountryCode(DEFAULT_PHONE_REGION)}
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              </div>
            </div>
            <FormGroup className={classes.checkboxGroup}>
              <FormControlLabel
                required
                control={(
                  <Checkbox
                    checked={isPersonalDataChecked}
                    onChange={() => setIsPersonalDataChecked((prev) => !prev)}
                  />
                )}
                label={(
                  <Typography className={classes.checkboxLink}>

                    {'Я прочитал и принимаю '}
                    <Link
                      onClick={() => { onLinkClick('/personal-data-processing-policy'); }}
                    >
                      политику обработки персональных данных
                    </Link>
                  </Typography>
                )}
              />
              <FormControlLabel
                required
                control={(
                  <Checkbox
                    checked={isAddonsTermsChecked}
                    onChange={() => setIsAddonsTermsCheked((prev) => !prev)}
                  />
                )}
                label={(
                  <Typography className={classes.checkboxLink}>
                    {'Я прочитал и принимаю '}
                    <Link
                      onClick={() => { onLinkClick('/addon-terms'); }}
                    >
                      правила использования addon-ов
                    </Link>
                  </Typography>
                )}
              />
            </FormGroup>
            <Box className={classes.buttonsWrapper}>
              <Button
                disabled={!isAddonsTermsChecked || !isPersonalDataChecked}
                variant="outlined"
                type="submit"
                className={classes.buttons}
              >
                Зарегистрироваться
              </Button>
              <div className={classes.linksWrapper}>
                <Link
                  href="/"
                  style={{
                    color: 'grey',
                    textDecorationColor: 'grey',
                  }}
                >
                  Обратно в developers
                </Link>
                <Link
                  href="/login"
                  style={{
                    color: 'grey',
                    textDecorationColor: 'grey',
                  }}
                >
                  Уже есть аккаунт?
                </Link>
              </div>
            </Box>
          </div>
        </form>
        <div className={classes.footerWrapper}>
          <Footer />
        </div>
      </Box>
    </div>
  );
}

export default Signup;
