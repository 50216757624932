import _ from 'lodash';
import cardSources from 'kaiten-lib/lib/shared/cardSources';
import comparisonsMap from 'kaiten-lib/lib/shared/comparisonsMap';
import filterTypes from 'kaiten-lib/lib/shared/filter3Types';
import customPropertyTypes from 'kaiten-lib/lib/shared/customPropertyTypes';

const fromToParams = {
  type: 'object',
  properties: {
    from: {
      type: 'string',
      description: 'Start of time range (format: ISO 8601)',
    },
    to: {
      type: 'string',
      description: 'End of time range (format: ISO 8601)',
    },
  },
};

const partialPathParams = {
  type: 'object',
  properties: {
    id: {
      type: 'number',
      description: 'Id of selected entity',
    },
    type: {
      type: 'string',
      description: 'Type of selected entity [column, lane, board, space]',
    },
    spaceId: {
      type: 'number',
      description: 'Id of selected space',
      required: 'no',
    },
    boardId: {
      type: 'number',
      description: 'Id of selected board',
      required: 'no',
    },
    parentId: {
      type: 'number',
      description: 'Id of selected parent column',
      required: 'no',
    },
  },
};

const customPropertyStringParams = {
  [comparisonsMap.eq]: {
    type: 'string',
  },
  [comparisonsMap.ne]: {
    type: 'string',
  },
  [comparisonsMap.starts_with]: {
    type: 'string',
  },
  [comparisonsMap.ends_with]: {
    type: 'string',
  },
  [comparisonsMap.contains]: {
    type: 'string',
  },
  [comparisonsMap.not_contains]: {
    type: 'string',
  },
  [comparisonsMap.known]: {
    type: null,
  },
  [comparisonsMap.unknown]: {
    type: null,
  },
};

const customPropertyNumberParams = {
  [comparisonsMap.eq]: {
    type: 'string',
  },
  [comparisonsMap.ne]: {
    type: 'string',
  },
  [comparisonsMap.gt]: {
    type: 'string',
  },
  [comparisonsMap.lt]: {
    type: 'string',
  },
  [comparisonsMap.known]: {
    type: null,
  },
  [comparisonsMap.unknown]: {
    type: null,
  },
};

const dateProps = {
  [comparisonsMap.today]: {
    ...fromToParams,
  },
  [comparisonsMap.yesterday]: {
    ...fromToParams,
  },
  [comparisonsMap.prev_seven_days]: {
    ...fromToParams,
  },
  [comparisonsMap.prev_thirty_days]: {
    ...fromToParams,
  },
  [comparisonsMap['lt:relative']]: {
    ...fromToParams,
  },
  [comparisonsMap['eq:relative']]: {
    ...fromToParams,
  },
  [comparisonsMap['gt:relative']]: {
    type: 'string',
  },
  [comparisonsMap.gt]: {
    type: 'string',
  },
  [comparisonsMap.eq]: {
    ...fromToParams,
  },
  [comparisonsMap.lt]: {
    type: 'string',
  },
};

const filterMap = {
  [filterTypes.owner_id]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
    [comparisonsMap.ne]: {
      type: 'number',
    },
  },
  [filterTypes.asap]: {
    [comparisonsMap.true]: {
      type: null,
    },
    [comparisonsMap.false]: {
      type: null,
    },
  },
  [filterTypes.tag]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
    [comparisonsMap.ne]: {
      type: 'number',
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.state]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
    [comparisonsMap.ne]: {
      type: 'number',
    },
  },
  [filterTypes.type_id]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
    [comparisonsMap.ne]: {
      type: 'number',
    },
  },
  [filterTypes.created]: {
    ...dateProps,
  },
  [filterTypes.updated]: {
    ...dateProps,
  },
  [filterTypes.first_moved_to_in_progress_at]: {
    ...dateProps,
    known: {
      type: null,
    },
    unknown: {
      type: null,
    },
  },
  [filterTypes.completed_at]: {
    ...dateProps,
    known: {
      type: null,
    },
    unknown: {
      type: null,
    },
  },
  [filterTypes.last_moved_at]: {
    ...dateProps,
  },
  [filterTypes.planned_start]: {
    ...dateProps,
    [comparisonsMap.tomorrow]: {
      ...fromToParams,
    },
    [comparisonsMap.next_seven_days]: {
      ...fromToParams,
    },
    [comparisonsMap.next_thirty_days]: {
      ...fromToParams,
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.planned_end]: {
    ...dateProps,
    [comparisonsMap.tomorrow]: {
      ...fromToParams,
    },
    [comparisonsMap.next_seven_days]: {
      ...fromToParams,
    },
    [comparisonsMap.next_thirty_days]: {
      ...fromToParams,
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.due_date]: {
    ...dateProps,
    [comparisonsMap.tomorrow]: {
      ...fromToParams,
    },
    [comparisonsMap.next_seven_days]: {
      ...fromToParams,
    },
    [comparisonsMap.next_thirty_days]: {
      ...fromToParams,
    },
    [comparisonsMap['overdue:custom']]: {
      type: null,
    },
    [comparisonsMap['completed_on_time:custom']]: {
      type: null,
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.source]: {
    [comparisonsMap.eq]: {
      type: 'enum',
      constraints: [..._.keys(cardSources)],
    },
    [comparisonsMap.ne]: {
      type: 'enum',
      constraints: [..._.keys(cardSources)],
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.responsible]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
    [comparisonsMap.ne]: {
      type: 'number',
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.member]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
    [comparisonsMap.ne]: {
      type: 'number',
    },
    [comparisonsMap.known]: {
      type: null,
    },
    [comparisonsMap.unknown]: {
      type: null,
    },
  },
  [filterTypes.partial_path]: {
    [comparisonsMap.eq]: {
      ...partialPathParams,
    },
    [comparisonsMap.ne]: {
      ...partialPathParams,
    },
  },
  [filterTypes.condition]: {
    [comparisonsMap.eq]: {
      type: 'number',
    },
  },
  [filterTypes.custom_property]: {
    [customPropertyTypes.string]: {
      ...customPropertyStringParams,
    },
    [customPropertyTypes.email]: {
      ...customPropertyStringParams,
    },
    [customPropertyTypes.url]: {
      ...customPropertyStringParams,
    },
    [customPropertyTypes.phone]: {
      ...customPropertyStringParams,
    },
    [customPropertyTypes.number]: {
      ...customPropertyNumberParams,
    },
    [customPropertyTypes.formula]: {
      ...customPropertyNumberParams,
    },
    [customPropertyTypes.collectiveVote]: {
      ...customPropertyNumberParams,
    },
    [customPropertyTypes.collectiveScore]: {
      ...customPropertyNumberParams,
      [comparisonsMap.contains]: {
        type: 'string',
      },
      [comparisonsMap.not_contains]: {
        type: 'string',
      },
    },
    [customPropertyTypes.date]: {
      [comparisonsMap.eq]: {
        ...fromToParams,
      },
      [comparisonsMap.gt]: {
        type: 'string',
      },
      [comparisonsMap.lt]: {
        type: 'string',
      },
      [comparisonsMap['eq:relative']]: {
        ...fromToParams,
      },
      [comparisonsMap['lt:relative']]: {
        ...fromToParams,
      },
      [comparisonsMap['gt:relative']]: {
        type: 'string',
      },
      [comparisonsMap.known]: {
        type: null,
      },
      [comparisonsMap.unknown]: {
        type: null,
      },
    },
    [customPropertyTypes.checkbox]: {
      [comparisonsMap.true]: {
        type: 'null',
      },
      [comparisonsMap.false]: {
        type: 'null',
      },
    },
    [customPropertyTypes.attachment]: {
      [comparisonsMap.known]: {
        type: 'null',
      },
      [comparisonsMap.unknown]: {
        type: 'null',
      },
    },
    [customPropertyTypes.select]: {
      [comparisonsMap.eq]: {
        type: 'number',
      },
      [comparisonsMap.ne]: {
        type: 'number',
      },
      [comparisonsMap.known]: {
        type: null,
      },
      [comparisonsMap.unknown]: {
        type: null,
      },
    },
    [customPropertyTypes.catalog]: {
      [comparisonsMap.eq]: {
        type: 'number',
      },
      [comparisonsMap.ne]: {
        type: 'number',
      },
      [comparisonsMap.known]: {
        type: null,
      },
      [comparisonsMap.unknown]: {
        type: null,
      },
    },
    [customPropertyTypes.user]: {
      [comparisonsMap.eq]: {
        type: 'string',
      },
      [comparisonsMap.ne]: {
        type: 'string',
      },
      [comparisonsMap.known]: {
        type: null,
      },
      [comparisonsMap.unknown]: {
        type: null,
      },
    },
    [customPropertyTypes.vote]: {
      [comparisonsMap.eq]: {
        type: 'string',
      },
      [comparisonsMap.ne]: {
        type: 'string',
      },
      [comparisonsMap.known]: {
        type: null,
      },
      [comparisonsMap.unknown]: {
        type: null,
      },
    },
  },
};

export default filterMap;
