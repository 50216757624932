import React from 'react';
import {
  Link, Box, Typography,
} from '@mui/material';
import { If } from 'react-if';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { get as registryGet } from '../../lib/appRegistry';

const LogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
}));

const DocumentLinkMenu = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

const DocumentLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textAlign: 'center',
  width: '100%',
  '&&:hover': {
    color: theme.palette.primary.main,
  },
}));

const SupportText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  textAlign: 'center',
  fontWeight: '400',
}));

const Wrapper = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.palette.divider,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
}));

function Footer({ withLinks }) {
  const {
    projectUrl, projectName, logoUrl, supportEmail,
  } = registryGet('config');

  return (
    <Wrapper>
      <LogoLink href={projectUrl}>
        <img src={logoUrl} className="logo-footer" alt="logo" />
        <Typography color="text.primary" variant="subtitle2">{projectName}</Typography>
      </LogoLink>
      <DocumentLinkMenu>
        <If condition={withLinks}>
          <>
            <DocumentLink
              href="/addon-terms"
              target="_blank"
            >
              <Typography variant="body2">Правила использования addon-ов</Typography>
            </DocumentLink>
            <DocumentLink
              href="/personal-data-processing-policy"
              target="_blank"
            >
              <Typography variant="body2">Политика в отношении обработки персональных данных</Typography>
            </DocumentLink>
          </>
        </If>
        <SupportText color="text.secondary" variant="subtitle2">
          {'Если у вас есть вопросы или нужна помощь с интеграцией, напишите нам на '}
          <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        </SupportText>
      </DocumentLinkMenu>
    </Wrapper>

  );
}

Footer.propTypes = {
  withLinks: PropTypes.bool,
};

Footer.defaultProps = {
  withLinks: false,
};

export default Footer;
